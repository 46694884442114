const release = document.querySelector('body')?.dataset?.release

if (typeof Sentry !== "undefined") {

    const host = window.location.host;

    let environment = "production";

    if (host.includes("bizzw.com")) environment = "staging";

    if (!host.includes(".local")) {
      Sentry.init({
        dsn: "https://c5f6512bd1ef41d4a2cd88f52ad42e4f@o410132.ingest.us.sentry.io/4504589700038656",
        integrations: [],
        tracesSampleRate: environment === "production" ? 0.1 : 1,
        environment,
        release
      });
    }

  }
  